$fa-font-path: '~@fortawesome/fontawesome-free/webfonts';

@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/brands';

@import './partials/header';
@import './partials/home';
@import './partials/about';
@import './partials/services';
@import './partials/facts';
@import './partials/blog';
@import './partials/footer';

@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');
// @import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@font-face {
  font-family: Arslan;
  src: url('../../src/assets/fonts/19306-alqabas-light.ttf');
}
h3,h2,h4,p,h5,li,ul{
  font-family: 'Arslan';

}
body {
  height: 100vh;
  font-family: 'Arslan';
}
.section-header {
  .section-title {
    font-weight: bold;
    color: #4e4e4e;
    font-family: 'Arslan' ;
    position: relative;
    margin-bottom: 2rem;
    span {
      color: #248eff;
    }
    &:after {
      content: '';
      content: '';
      display: block;
      height: 3px;
      width: 70px;
      background: #332C27;
      position: absolute;
      top: 45px;
      left: 50%;
      transform: translateX(-50%);
      font-family: 'Arslan';

    }
  }
  .section-subtitle {
    max-width: 700px;
    color: #6f6f6f;
    font-size: 0.9rem;
  }
}
.heading-title {
  margin-bottom: 100px;
}
.text-center {
  text-align: center;
}
.heading-title h3 {
  margin-bottom: 0;
   font-weight: normal;
}
.p-top-30 {
  padding-top: 30px;
}
.half-txt {
  width: 60%;
  margin: 0 auto;
  display: inline-block;
  line-height: 25px;
  color: #7e7e7e;
}
.text-uppercase {
  text-transform: uppercase;
}

.team-member, .team-member .team-img {
  position: relative;
}
.team-member {
  overflow: hidden;
}
.team-member, .team-member .team-img {
  position: relative;
 
}

.team-hover {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: 0;
  border: 20px solid rgba(0, 0, 0, 0.1);
  background-color: rgba(255, 255, 255, 0.90);
  opacity: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.team-member:hover .team-hover .desk {
  top: 45%;
}
.team-member:hover .team-hover, .team-member:hover .team-hover .desk, .team-member:hover .team-hover .s-link {
  opacity: 1;
}
.team-hover .desk {
  position: absolute;
  top: 0%;
  width: 100%;
  opacity: 0;
  -webkit-transform: translateY(-55%);
  -ms-transform: translateY(-55%);
  transform: translateY(-55%);
  -webkit-transition: all 0.3s 0.2s;
  transition: all 0.3s 0.2s;
  padding: 0 20px;
  font-family: 'Arslan';
}
.desk, .desk h4, .team-hover .s-link a {
  text-align: center;
  color: #222;
}
.team-member:hover .team-hover .s-link {
  bottom: 10%;
}
.team-member:hover .team-hover, .team-member:hover .team-hover .desk, .team-member:hover .team-hover .s-link {
  opacity: 1;
}
.team-hover .s-link {
  position: absolute;
  bottom: 0;
  width: 100%;
  opacity: 0;
  text-align: center;
  -webkit-transform: translateY(45%);
  -ms-transform: translateY(45%);
  transform: translateY(45%);
  -webkit-transition: all 0.3s 0.2s;
  transition: all 0.3s 0.2s;
  font-size: 35px;
  font-family: 'Arslan';
}

.desk, .desk h6, .team-hover .s-link a {
  text-align: center;
  color: #222;
  font-family: 'Arslan';

}
.team-member .s-link a {
  margin: 0 10px;
  color: #333;
  font-size: 16px;
  font-family: 'Arslan';
}

.team-title {
  position: static;
  padding: 20px 0;
  display: inline-block;
   width: 100%;
  font-family: 'Arslan';

}
.team-title h5 {
  margin-bottom: 0px;
  display: block;
  text-transform: uppercase;
  font-family: 'Arslan';
}
.team-title span {
  font-size: 12px;
  text-transform: uppercase;
  color: #a5a5a5;
   font-family: 'Arslan';
}
table {
  font-family: 'Arslan';
    border-collapse: collapse;
  width: 100%;
}

 






.modal-window {
  position: fixed;
  background-color: rgba(200, 200, 200, 0.75);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  opacity: 0;
  pointer-events: none;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
  font-family: 'Arslan';
}

.modal-window:target {
  opacity: 1;
  pointer-events: auto;
}

.modal-window>div {
 
  position: relative;
  margin: 10% auto;
  padding: 2rem;
  background: #fff;
  color: #444;
  max-height: 600px;
width: 375px;
  overflow: auto;
  font-family: 'Arslan';
}

.modal-window header {
  font-weight: bold;
  font-family: 'Arslan';
}

.modal-close {
  color: #aaa;
  line-height: 30px;
  font-size: 100%;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: 50px;
  text-decoration: none;
  
 
}

.modal-close:hover {
  color: #000;

}

.modal-window h1 {
  font-size: 150%;
  margin: 0 0 15px;
  font-family: 'Arslan';
  
}

.tt{
  padding-left: 10px;
  padding-right: 10px;
 
 

}

.custom-list {
  list-style-type: circle;
     }